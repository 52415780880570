import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { TextField, Button, Typography, Paper } from "@material-ui/core";

import { createPost } from "../../actions/posts";
import { updatePost } from "../../actions/posts";

const Form = ({ currentId, setCurrentId }) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("profile"));
  const post = useSelector((state) =>
    currentId ? state.posts.find((message) => message._id === currentId) : null
  );
  const dispatch = useDispatch();
  const [postData, setPostData] = useState({
    title: " ",
    price: " ",
    quantity: " ",
    purchaseDate: "",
    tags: "",
  });

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);
  const handleSubmit = async (e) => {
    //send post request
    e.preventDefault();

    if (currentId) {
      dispatch(
        updatePost(currentId, { ...postData, name: user?.result?.name })
      );
    } else {
      dispatch(createPost({ ...postData, name: user?.result?.name }));
    }
    clear();
  };

  const clear = () => {
    setCurrentId(null);
    setPostData({
      title: " ",
      price: " ",
      quantity: " ",
      purchaseDate: "",
      tags: "",
    });
  };

  return (
    <Paper className={classes.paper}>
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6">
          {currentId ? "Editing " : "Logging"} Recent Trades
        </Typography>

        <TextField
          name="Title"
          variant="outlined"
          label="Stock Name"
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        <TextField
          name="tags"
          variant="outlined"
          label="Tags (Please seperate with comma)"
          fullWidth
          value={postData.tags}
          onChange={(e) => setPostData({ ...postData, tags: e.target.value })}
        />

        <TextField
          name="price"
          variant="outlined"
          label="Price"
          fullWidth
          value={postData.price}
          onChange={(e) => setPostData({ ...postData, price: e.target.value })}
        />
        <TextField
          name=" quantity"
          variant="outlined"
          label=" Qantity"
          fullWidth
          value={postData.quantity}
          onChange={(e) =>
            setPostData({ ...postData, quantity: e.target.value })
          }
        />
        <TextField
          name=" purchaseDate"
          variant="outlined"
          label="Purchase Date (MM/DD/YYYY)"
          fullWidth
          value={postData.purchaseDate}
          onChange={(e) =>
            setPostData({ ...postData, purchaseDate: e.target.value })
          }
        />
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          style={{ backgroundColor: "#1BAA79", color: "white" }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#FCBB13", color: "white" }}
          size="small"
          onClick={clear}
          fullWidth
        >
          Clear
        </Button>
      </form>
    </Paper>
  );
};

export default Form;
