import React from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import Main from "./pages/main";
import Auth from "../src/components/Auth/Auth";
import Dash from "./pages/dash";
import StockHome from "./pages/stockHome";
import CryptoHome from "./pages/cryptoHome";
import TrendStock from "./pages/trendStock";
import Facebook from "./pages/facebook";
import Tesla from "./pages/tesla";
import Amazon from "./pages/amazon";
import Google from "./pages/google";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/signin" component={SigninPage} exact />

        <Route path="/main" component={Main} exact />
      </Switch>
      <Route path="/auth" component={Auth} exact />
      <Route path="/dash" component={Dash} exact />
      <Route path="/stockHome" component={StockHome} exact />
      <Route path="/crypto" component={CryptoHome} exact />
      <Route path="/trending" component={TrendStock} exact />
      <Route path="/facebook" component={Facebook} exact />
      <Route path="/tesla" component={Tesla} exact />
      <Route path="/amazon" component={Amazon} exact />
      <Route path="/google" component={Google} exact />
    </Router>
  );
}

export default App;
