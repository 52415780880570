import React from 'react';
import './widgetslarge.css';
import StockRow from './StockRow.js'
import img5 from './img8.png';

export default function WidgetsLarge() {
    return (
        <div className="widgetLarge">
            <div className="image">
            <img src={img5}/>  
            </div>
           <br></br>
           <h1 className="text">Popular Trades</h1>
           <p className="text">Fact of the day : There are 19 stock exchanges in the world with a market capitalization of more than $1 trillion.</p>
  
          
        </div>
    )
}
