import React from "react";
import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/modSide/Sidbar";
import "./dash.css";
import Featuredinfo from "../components/featuredinfo/Featuredinfo";
import Charts from "../components/charts/Charts";
import WidgetsLarge from "../components/WidgetsLarge/WidgetsLarge";
import WidgetSmall from "../components/widgetsSmall/WidgetSmall";
const dash = () => {
  return (
    <div>
      <Topbar></Topbar>
      <div className="container">
        <Sidebar />
        <div className="others">
          {" "}
          <Featuredinfo />
          <Charts></Charts>
          <div className="homeWidgets">
            {/*<WidgetsLarge />
            <WidgetSmall />
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default dash;
