import React,{useEffect,useState} from 'react';
import './featuredinfo.css';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import axios from "axios";
export default function Featuredinfo() {
    const [regMarPer, setRegMarPer] = useState("");
    const [exchange, setExchange] = useState("");
    const [shortName, setShortName] = useState("");
    const [longName, setLongName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [regMarOpen, setRegMarOpen] = useState("");
    const [regMarHigh, setRegMarHigh] = useState("");
    const [regMarLow, setRegMarLow] = useState("");
    const [regMarPrice, setRegMarPrice] = useState("");
    const [regMarVol, setRegMarVol] = useState("");
    const [regMicroMarSymb, setMicroSymbol] = useState("");
    const [regMicroMarPrice, setMicroRegMarPrice] = useState("");
    const [regMicroMarVol,  setMicroRegMarVol] = useState("");
    const [tesSymbol,teslaSymbol]= useState("");
    const [ tesPrice,teslaPrice]=useState("");
    const [tesVolume,teslaVolume] = useState("");
    useEffect(() => {
        var axios = require("axios").default;

        var options = {
          method: 'GET',
  url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-statistics',
  params: {symbol: 'MSFT', region: 'US'},
  headers: {
    'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
    'x-rapidapi-key': '3ab13f2332mshee443c1cc10ad3fp19da31jsnbfc9f4c7d086'
          }
        };
        
        axios.request(options).then(function (response) 
        {
        console.log(response.data);
        setExchange(response.data.price.exchange);
        setRegMarPer(response.data.price.regularMarketChangePercent.fmt);
        setShortName(response.data.price.shortName);
        setLongName(response.data.price.longName);
        setSymbol(response.data.price.symbol);
        setRegMarOpen(response.data.price.regularMarketOpen.fmt);
        setRegMarHigh(response.data.price.regularMarketDayHigh.fmt);
        setRegMarLow(response.data.price.regularMarketDayLow.fmt);
        setRegMarPrice(response.data.price.regularMarketPrice.fmt);
        setRegMarVol(response.data.price.regularMarketVolume.fmt);
        }).catch(function (error) {
            console.error(error);
        });

        var axios = require("axios").default;

var micro = {
  method: 'GET',
  url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-statistics',
  params: {symbol: 'INTC', region: 'US'},
  headers: {
    'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
    'x-rapidapi-key': '3ab13f2332mshee443c1cc10ad3fp19da31jsnbfc9f4c7d086'
  }
};

axios.request(micro).then(function (response) {
    setExchange(response.data.price.exchange);
    setRegMarPer(response.data.price.regularMarketChangePercent.fmt);
    setShortName(response.data.price.shortName);
    setLongName(response.data.price.longName);
    setMicroSymbol(response.data.price.symbol);
    setRegMarOpen(response.data.price.regularMarketOpen.fmt);
    setRegMarHigh(response.data.price.regularMarketDayHigh.fmt);
    setRegMarLow(response.data.price.regularMarketDayLow.fmt);
    setMicroRegMarPrice(response.data.price.regularMarketPrice.fmt);
    setMicroRegMarVol(response.data.price.regularMarketVolume.fmt);
}).catch(function (error) {
	console.error(error);
});
     

var tesla = {
  method: 'GET',
  url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-statistics',
  params: {symbol: 'HPQ', region: 'US'},
  headers: {
    'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
    'x-rapidapi-key': '3ab13f2332mshee443c1cc10ad3fp19da31jsnbfc9f4c7d086'
  }
};

axios.request(tesla).then(function (response) {
    teslaSymbol(response.data.price.symbol);
    teslaPrice(response.data.price.regularMarketPrice.fmt);
    teslaVolume(response.data.price.regularMarketVolume.fmt);
    
}).catch(function (error) {
	console.error(error);
});

    },[]);
  
        
    return (
        <div className="featured">
            <div className="featuredItem">

                <span className="featuredTitle">{symbol}</span>
                <div className="featuredMoneyContainer">
                    <span className="featuredMoney">${regMarPrice}</span>
                    <span className="featuredMoneyRate">{regMarVol}<ArrowDownwardIcon className="featuredIcon negative"/></span>
                    <span className="featuredSub">Market Volume</span>
                </div>
            </div>
            <div className="featuredItem">

            <span className="featuredTitle">{regMicroMarSymb}</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">${regMicroMarPrice}</span>
                <span className="featuredMoneyRate">{regMicroMarVol}<ArrowUpwardIcon className="featuredIcon negative" /></span>
                <span className="featuredSub">Market Volume</span>
            </div>
            </div>
            <div className="featuredItem">

            <span className="featuredTitle">{tesSymbol}</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">${tesPrice}</span>
                <span className="featuredMoneyRate">{tesVolume}<ArrowUpwardIcon className="featuredIcon"/></span>
                <span className="featuredSub">Market Volume</span>
            </div>
            </div>
        </div>
    )
}
