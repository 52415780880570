export const homeObjOne = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Pioneering Trading",
  headline: "Track , Watch , Communicate ",
  description:
    "This platform will allow you to follow live updates of the hottest stocks on the market and to see what other buyers are purchasing",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../images/svg-3.svg"),
  alt: "Car",
  dark: false,
  primary: true,
  darkText: true,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "SECURE YOUR INVESTMENTS",
  headline: "Never lose focus of your stocks",
  description:
    "Youv'e invested , now the real work begins.Let us do the work for you. Envok will aid in managing your assets.",
  buttonLabel: "Learn More",
  imgStart: false,
  img: require("../../images/svg-9.svg"),
  alt: "Piggybank",
  dark: false,
  primary: false,
  darkText: false,
};
