import React from 'react';
import './widgetslarge.css';
import StockRow from './StockRow.js'
import img5 from './img6.png';

export default function WidgetsLarge() {
    return (
        <div className="widgetLarge">
            <div className="image">
            <img src={img5}/>  
            </div>
           
           <h1 className="text">Pro Tip</h1>
           <p className="text">Remember to check on coin prices as they can change by the day. Naviagte to the leaderboards to keep track of stocks other users are buying.</p>
  
           
        </div>
    )
}
