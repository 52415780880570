import React from "react";
import Topbar from "../components/topbar/topbar";

import Featured2 from "../components/featuredinfo/Featured2";
import Featured3 from "../components/featuredinfo/Featured3";
import Featured4 from "../components/featuredinfo/Featured4";
import WidgetsLarge from "../components/WidgetsLarge/WidgetLarge2";
import Sidebar from "../components/modSide/Sidbar";
import "./dash.css";
function trendStock() {
  return (
    <div>
      <Topbar></Topbar>
      <div className="container">
        <Sidebar />

        <div className="others">
          {" "}
          <div className="homeWidgets">{<WidgetsLarge />}</div>
          <Featured2 />
          <Featured3 />
        </div>
      </div>
    </div>
  );
}

export default trendStock;
