import React,{useEffect,useState} from 'react';
import './featuredinfo.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
  } from "react-router-dom";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Amazon from './amazon.png';
import Google from './google.png';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
export default function Featuredinfo() {  
    let history = useHistory();   
    return (
        <Router>
        <div className="featured">
            <div className="featuredItem">
             
                <span className="featuredTitle"></span>
                <div className="image">
                <img src={Amazon} width="70"/>
                </div>
                <br></br>
                <h2>Amazon</h2>
                 <div className="featuredMoneyContainer">
                 <span className="featuredSub">Amazon.com, Inc. is an American multinational technology company which focuses on e-commerce, cloud computing, digital streaming, and artificial intelligence.</span>                     
                </div>
                <div className="icon-style"onClick={()=>{history.push('/amazon')}}>
            <TrendingUpIcon style={{ color: "#1BAA79" }}></TrendingUpIcon> 
            
            See Charts
            </div>
            </div>
            
            <div className="featuredItem">

            <span className="featuredTitle"></span>
            <div className="image">
                <img src={Google} width="70"/>
                </div>
                <br></br>
                <h2>Google</h2>
                 <div className="featuredMoneyContainer">
                 <span className="featuredSub">Google LLC is an American multinational technology company that specializes in Internet-related services and products, which include online advertising technologies, a search engine, cloud computing, software, and hardware</span>
            
            </div>
            <div className="icon-style" onClick={()=>{history.push('/google')}}>
                
            <TrendingUpIcon style={{ color: "#1BAA79" }} onClick={()=>history.push('/google')}></TrendingUpIcon> 
            
            See Charts
            </div>
        </div>
        </div>
        </Router>
    )
}
