import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Side from "../../src/components/Side/Side";

import { getPosts } from "../actions/posts";
import { Container, AppBar, Typography, Grow, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Posts from "../Posts/Posts.js";
import Form from "../components/Forms/Form.js";
import useStyles from "./styles";
import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/modSide/Sidbar";
import "./dash.css";
const Main = () => {
  const [currentId, setCurrentId] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, dispatch]);
  return (
    <Router>
      <Topbar />
      <div className="container">
        <div className="others">
          <Container maxWidth="lg" className={classes.appBar}>
            <Grow in>
              <Container>
                <Grid
                  className={classes.mainContainer}
                  container
                  justify="Space-between"
                  alignItems="stretch"
                  spacing="3"
                  margin="40px 50px"
                >
                  <Grid item xs={12} sm={7}>
                    <Posts setCurrentId={setCurrentId} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Form currentId={currentId} setCurrentId={setCurrentId} />
                  </Grid>
                </Grid>
              </Container>
            </Grow>
          </Container>
        </div>
      </div>
    </Router>
  );
};

export default Main;
