import React, { useState, useEffect } from "react";
import"./sidebar.css";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
  } from "react-router-dom";
import TimelineIcon from '@material-ui/icons/Timeline';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ListIcon from '@material-ui/icons/List';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import useStyles from "../Side/sideStyle";
import { useDispatch } from "react-redux";
import Stockhome from '../../pages/stockHome';
import { AppBar, Avatar, Typography, Button, Toolbar } from "@material-ui/core";
const Sidbar = () => {
    const classes = useStyles();
    let history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

    const logout = () => {
        dispatch({ type: "LOGOUT" });
        history.push("auth");
        window.location.reload();
    
        setUser(null);
      };
      useEffect(() => {
        const token = user?.token;
    
        //JWT
    
        setUser(JSON.parse(localStorage.getItem("profile")));
      }, [location]);
    return (
        <Router>
      
        <div className="sidebar">
           <div className="sidebarWrapper">
                <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Dashboard</h3>
                        <ul className="sidebarList">
                            <li className="sidebarlistItem "  onClick={()=>{
                                history.push("/dash")
                            }}>
                           
                          
                                <DragHandleIcon className="sidebarIcon"/>
                               Home
                           
                             
                            </li>
                            
                            
                        </ul>
                 </div>
                 <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Crypto</h3>
                        <ul className="sidebarList">
                            
                            <li className="sidebarlistItem" onClick={()=>{
                                window.open('./stockHome');
                            }}>
                                <MonetizationOnIcon className="sidebarIcon"/>
                               Track Coin
                            </li>
                            <li className="sidebarlistItem" onClick={()=>{
                                history.push("/crypto")}}>
                                <TimelineIcon className="sidebarIcon"/>
                                Analytics
                            </li>
                        </ul>
                 </div>
                 <div className="sidebarMenu">
                     <h3 className="sidebarTitle">Stocks</h3>
                        <ul className="sidebarList">
                           
                            
                           
                        </ul>
                 </div>
             
           </div>
        </div>
       
        </Router>
    )
}

export default Sidbar
