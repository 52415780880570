import React, { useState, useEffect } from "react";
import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/modSide/Sidbar";
import "./dash.css";
import axios from "axios";
import Coin from "./Coin";
import "./Coin.css";
export default function StockHome() {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
      )
      .then((res) => {
        setCoins(res.data);
        console.log(res.data);
      })
      .catch((error) => console.log(error));
  }, []);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <Topbar></Topbar>
      <div className="container">
        <div className="others">
          {""}
          <div className="coin-app">
            <div className="coin-search">
              <h1 className="coin-text">Search a currency</h1>
              <form>
                <input
                  className="coin-input"
                  type="text"
                  onChange={handleChange}
                  placeholder="Search"
                />
              </form>
            </div>
            {filteredCoins.map((coin) => {
              return (
                <Coin
                  key={coin.id}
                  name={coin.name}
                  price={coin.current_price}
                  symbol={coin.symbol}
                  marketcap={coin.market_cap}
                  volume={coin.total_volume}
                  image={coin.image}
                  priceChange={coin.price_change_percentage_24h}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
