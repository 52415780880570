import React from "react";
import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/modSide/Sidbar";
import Charts from "../components/charts/Charts";
import WidgetsLarge from "../components/WidgetsLarge/WidgetsLarge";
import WidgetSmall from "../components/widgetsSmall/WidgetSmall";
import "./dash.css";
function cryptoHome() {
  return (
    <div>
      <Topbar></Topbar>
      <div className="container">
        <Sidebar />
        <div className="others">
          {""}
          <div className="homeWidgets">{<WidgetsLarge />}</div>
          <Charts></Charts>
        </div>
      </div>
    </div>
  );
}

export default cryptoHome;
