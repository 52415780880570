import React from "react";
import Icon1 from "../../images/svg-10.svg";
import Icon2 from "../../images/svg-11.svg";
import Icon3 from "../../images/svg-12.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>View Stocks</ServicesH2>
          <ServicesP>
            We will show you live updates of stocks and crypto currency
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Track Purchases</ServicesH2>
          <ServicesP>
            Save time and energy by seeing your data displayed in multiple ways{" "}
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Communicate with others</ServicesH2>
          <ServicesP>
            See what popular stocks and crypto other users are purchasing
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
