import React from "react";
import useStyles from "./styles";
import "./card.css";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core/";
import { useDispatch } from "react-redux";

import Image from "./im10.png";

import CreateIcon from "@material-ui/icons/Create";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";

import { deletePost, likePost } from "../../actions/posts";

const Post = ({ post, setCurrentId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const Likes = () => {
    if (post.likes.length > 0) {
      return post.likes.find(
        (like) => like === (user?.result?.googleId || user?.result?._id)
      ) ? (
        <>
          <ThumbUpAltIcon fontSize="small" />
          &nbsp;
          {post.likes.length > 2
            ? `You and ${post.likes.length - 1} others`
            : `${post.likes.length} like${post.likes.length > 1 ? "s" : ""}`}
        </>
      ) : (
        <>
          <ThumbUpAltOutlinedIcon fontSize="small" />
          &nbsp;{post.likes.length} {post.likes.length === 1 ? "Like" : "Likes"}
        </>
      );
    }

    return (
      <>
        <ThumbUpAltOutlinedIcon fontSize="small" />
        &nbsp;Like
      </>
    );
  };

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={Image} title={post.title} />
      <div className={classes.overlay}>
        <Typography variant="h4">{post.title}</Typography>
        <Typography variant="h6">{post.name}</Typography>
      </div>
      {(user?.result?.googleId === post?.creator ||
        user?.result?._id === post?.creator) && (
        <div className={classes.overlay2}>
          <Button
            style={{ color: "#1BAA79" }}
            size="small"
            onClick={() => setCurrentId(post._id)}
          >
            <CreateIcon
              fontsize="default"
              style={{ color: "white" }}
            ></CreateIcon>
          </Button>
        </div>
      )}

      <CardContent>
        {""}
        <div className="shift">
          <Typography variant="body1">Price : ${post.price}</Typography>
          <Typography variant="body1">Quanitity : {post.quantity}</Typography>
          <Typography variant="body1">
            Purchase Time : {post.purchaseDate}
          </Typography>
        </div>
      </CardContent>
      <CardActions className={classes.cardActions}>
        {(user?.result?.googleId === post?.creator ||
          user?.result?._id === post?.creator) && (
          <Button
            size="small"
            style={{ color: "#1BAA79" }}
            onClick={() => dispatch(deletePost(post._id))}
          >
            <DeleteIcon style={{ color: "#1BAA79" }} fontSize="small" /> Delete
          </Button>
        )}
        <Typography variant="body2" color="textSecondary">
          {post.tags.map((tag) => `#${tag} `)}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default Post;
