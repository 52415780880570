import { AUTH } from "../constants/actionTypes";
import * as api from "../api";
import { useState, useEffect } from "react";

{
  /*export const signin = (formData, history) => async (dispatch) => {
  const [error, setError] = useState(null);
  try {
    const { data } = await api.signIn(formData);

    dispatch({ type: AUTH, data });

    history.push("/dash");
  } catch (error) {
   setError(error.message);
};
*/
}

export const signup = (formData, history) => async (dispatch) => {
  try {
    //signup the user
    const { data } = await api.signUp(formData);

    dispatch({ type: AUTH, data });

    history.push("/dash");
  } catch (error) {
    console.log(error);
  }
};
