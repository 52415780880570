import React,{useEffect,useState} from 'react';
import './featuredinfo.css';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import axios from "axios";
import Facebook from './facebook.png';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import Tesla from './tesla.png';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
export default function Featuredinfo() {
  let history = useHistory();  
        
    return (
      <div className="featured">
      <div className="featuredItem">
       
          <span className="featuredTitle"></span>
          <div className="image">
          <img src={Facebook} width="70"/>
          </div>
          <br></br>
          <h2>Facebook</h2>
           <div className="featuredMoneyContainer">
           <span className="featuredSub">Facebook, Inc., is an American multinational technology company based in Menlo Park, California</span>   
            </div>
            <div className="icon-style" onClick={()=>{history.push('/facebook')}}>
            <TrendingUpIcon style={{ color: "#1BAA79" }}></TrendingUpIcon> 
            
            See Charts
            </div>
      </div>
      
      <div className="featuredItem">

      <span className="featuredTitle"></span>
      <div className="image">
          <img src={Tesla} width="70"/>
          </div>
          <br></br>
          <h2>Tesla</h2>
           <div className="featuredMoneyContainer">
           <span className="featuredSub">Tesla designs and manufactures electric cars, battery energy storage from home to grid-scale, solar panels and solar roof tiles, and related products and services</span>
      
      </div>
      <div className="icon-style" onClick={()=>{history.push('/tesla')}}>
            <TrendingUpIcon style={{ color: "#1BAA79" }}></TrendingUpIcon> 
            
            See Charts
            </div>
  </div>
  </div>
    )
}

