import React from "react";

import Topbar from "../components/topbar/topbar";
import Sidebar from "../components/modSide/Sidbar";
import "./dash.css";

import Charts from "../components/charts/Stock4";
function amazon() {
  return (
    <div>
      <div>
        <Topbar></Topbar>
        <div className="container">
          <Sidebar />
          <div className="others">
            {" "}
            <Charts></Charts>
            <div className="homeWidgets"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default amazon;
