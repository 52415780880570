import React, { useState, useEffect } from "react";
import "./topbar.css";
import "../Side/side.css";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation,
  } from "react-router-dom";
  
import LanguageIcon from '@material-ui/icons/Language';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useDispatch } from "react-redux";
import { AppBar, Avatar, Typography, Button, Toolbar } from "@material-ui/core";
import useStyles from "../Side/sideStyle";
import Search from '../SearchBar/Search'
const Topbar = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  
    const logout = () => {
      dispatch({ type: "LOGOUT" });
      history.push("auth");
      window.location.reload();
  
      setUser(null);
    };
    useEffect(() => {
      const token = user?.token;
  
      //JWT
  
      setUser(JSON.parse(localStorage.getItem("profile")));
    }, [location]);
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo">Envok</span>
                    </div>

        
                <div className="topRight">
                    <Typography
              className={classes.userName}
              variant="h6"
              style={{ color: "black" }}
            >
              Welcome{"   "} {user?.result.name}
            </Typography>
                <div className={classes.profile}>
          <Toolbar className={classes.toolbar}>
            {user?.result ? (
              <div className={classes.profile}>
                <Avatar
                  className={classes.purple}
                  alt={user?.result.name}
                  src={user?.result.imageUrl}
                >
                  {user?.result.name.charAt(0)}
                </Avatar>
              </div>
            ) : (
              <Button
                component={Link}
                to="/auth"
                variant="contained"
                color="primary"
              >
                Launch
              </Button>
            )}
          </Toolbar>
          
        </div>
                   {/* <div className="topbarIconContainer">

                    <NotificationsNoneIcon/>
                    <span className="topIconBadge">2</span>
                    </div>
                    <div className="topbarIconContainer">

                    <LanguageIcon/>
                    <span className="topIconBadge">2</span>
                    </div>
                    <div className="topbarIconContainer">

                    <SettingsIcon/>
                
                    </div>
                    <img src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.picmonkey.com%2Fblog%2Fcreate-the-best-profile-pic&psig=AOvVaw3Ds9r8XHUj4EJlpMVoBz2G&ust=1625588274513000&source=images&cd=vfe&ved=0CAcQjRxqFwoTCKi1gbCqzPECFQAAAAAdAAAAABAD" alt="" className="topAvatar"/>
            */}
                    </div>
            </div>
           
        </div>
    )
}

export default Topbar
